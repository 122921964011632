import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import Base from '../components/base';
import SEO from '../components/seo';
import { formatRawCareer } from '../utilities';
import Sides from '../components/ranges/sides';
import Info from '../components/info';
import SmallCard from '../components/smallCard';

const Careers = ({
  location,
  data: { careers: rawCareers },
}: PageProps<Queries.CareersQuery>) => {
  const { t } = useI18next();
  const careers = rawCareers.nodes.map(formatRawCareer);
  return (
    <Base location={location}>
      <div className='bg-cover bg-[url("../images/careers-landing.webp")] lg:rounded-lg'>
        <div className="bg-gradient-to-r from-black to-black/50 lg:rounded-lg">
          <Sides>
            <div className="py-[5.625rem] lg:py-[8.75rem]">
              <Info title={t('Welcome onboard future #cyberrangers')}>
                <p>
                  <Trans>
                    We are growing and looking for new talents to join our
                    Multiverse of Cyber Ranges. CR14 is a small and evolving
                    company with around 50 #cyberrangers. Half of them work for
                    NATO CCDCOE as Estonian representatives and part of host
                    nation support, and another half directly for CR14.
                  </Trans>
                </p>
              </Info>
            </div>
          </Sides>
        </div>
      </div>
      <div className="bg-cover bg-black lg:rounded-lg">
        <Sides>
          <div className="pt-[5.625rem] lg:pt-[8.75rem]">
            <Info title={t('What we can offer you as our new team member?')}>
              <ul>
                <li>
                  <Trans>A young and rapidly developing organization</Trans>
                </li>
                <li>
                  <Trans>
                    Daily learning opportunities on cyber security topics
                  </Trans>
                </li>
                <li>
                  <Trans>
                    Our field is growing - cyber security is becoming
                    increasingly important and in demand
                  </Trans>
                </li>
                <li>
                  <Trans>
                    Our organization is small enough to know each of your
                    colleagues in person
                  </Trans>
                </li>
                <li>
                  <Trans>Flexible working time and - place</Trans>
                </li>
                <li>
                  <Trans>
                    In addition to the above, we offer various benefits that
                    support your health and well-being, like sports
                    compensation, extra days off, and additional employer health
                    insurance
                  </Trans>
                </li>
              </ul>
              <br />
              <p>
                <Trans>
                  If you are interested in an internship or doing project work
                  during your studies, please get in touch with us on
                  jobs@cr14.ee
                </Trans>
              </p>
              <br />
              <p>
                <Trans>
                  Join us and make Estonia and NATO a safer place! Open
                  positions below!
                </Trans>
              </p>
            </Info>
          </div>
        </Sides>
        <Sides>
          <div className="py-[5.625rem] lg:py-[8.75rem]">
            {careers.length === 0 && (
              <div>
                <h3>
                  <Trans>No careers posted at the moment</Trans>
                </h3>
                <br />
                <br />
                <h3>
                  <Trans>Check back soon!</Trans>
                </h3>
              </div>
            )}
            <div className="grid gap-[3.575rem] lg:gap-[5rem] grid-cols-1 lg:grid-cols-2">
              {careers.map(({ title, externalLink, content }) => (
                <SmallCard
                  gradientFrom="from-black"
                  videoURL="/videos/video_ECR"
                  link={externalLink}
                  key={title}
                  title={title}
                  titleSize="small"
                  externalLink
                >
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: content }}
                    className="mt-[3.75rem] career-content"
                  />
                </SmallCard>
              ))}
            </div>
          </div>
        </Sides>
      </div>
    </Base>
  );
};

export default Careers;

export const pageQuery = graphql`
  query Careers($language: String!) {
    careers: allMarkdownRemark(
      filter: {
        fields: { sourceName: { eq: "career" } }
        frontmatter: { language: { eq: $language } }
      }
    ) {
      nodes {
        html
        frontmatter {
          title
          externalLink
        }
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => <SEO title="CR14 Careers" />;
